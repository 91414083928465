import React from 'react';
import DropdownComponent from './DropdownComponent';
import ComplexList from './ComplexList';

export default function SearchAreaContainer({
  selectedRegion,
  siDoData,
  handleRegionChange,
  selectedCity,
  siGunGuData,
  handleCityChange,
  selectedTown,
  eupMyeonDongData,
  handleTownChange,
  complexes,
  setFormData,
  getAptInfo
}) {
  return (
    <>
      <div className='m-2 p-2 flex flex-col md:flex-row'>
        <div className='w-full md:w-1/3 mb-4 md:mb-0 md:pr-2'>
          <h1 className='text-l md:text-xl font-semibold mb-1'>지역 선택</h1>
          <DropdownComponent
            placeHold="지역을 선택하세요"
            value={selectedRegion}
            options={siDoData}
            onChange={handleRegionChange}
          />
        </div>
        {selectedRegion && (
          <div className='w-full md:w-1/3 mb-4 md:mb-0 md:px-2'>
            <h1 className='text-l md:text-xl font-semibold mb-1'>시/군/구 선택</h1>
            <DropdownComponent
              placeHold="시/군/구를 선택하세요"
              value={selectedCity}
              options={siGunGuData}
              onChange={handleCityChange}
            />
          </div>
        )}
        {selectedRegion && selectedCity && (
          <div className='w-full md:w-1/3 md:pl-2'>
            <h1 className='text-l md:text-xl font-semibold mb-1'>읍/면/동 선택</h1>
            <DropdownComponent
              placeHold="읍/면/동을 선택하세요"
              value={selectedTown}
              options={eupMyeonDongData}
              onChange={handleTownChange}
            />
          </div>
        )}
      </div>
      {selectedRegion && selectedCity && complexes.length > 0 && (
        <ComplexList complexes={complexes} setFormData={setFormData} getAptInfo={getAptInfo} />
      )}
    </>
  );
}