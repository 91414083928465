//pages/Home.js

import { useState } from 'react';
import TableComponent from '../components/TableComponent';
import SearchForm from '../components/SearchForm';
import { columnOrderForAptNoInfo, columnOrderForAptInfo, columnOrderForAptA1B1Info } from '../constant/columnsName';
import Spin from '../components/Spin';
import SearchAptNoForm from '../components/SearchAptNoForm';
import SearchByRegion from '../components/SearchByRegion';
import ResultOfApartmentContainer from '../components/ResultOfApartmentContainer';


export default function Home() {
    const [aptName, setAptName] = useState("");
    const [formData, setFormData] = useState({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""});
    const [aptNoInfo, setAptNoInfo] = useState(null);
    const [aptInfo, setAptInfo] = useState(null);
    const [aptA1B1ListInfo, setAptA1B1ListInfo] = useState(null);
    const [isFetchingAptNoInfo, setIsFetchingAptNoInfo] = useState(false);
    const [isFetchingAptInfo, setIsFetchingAptInfo] = useState(false);
    const [isFetchingAptA1B1Info, setIsFetchingAptA1B1Info] = useState(false);
    const [aptNoInfoFetchError, setAptNoInfoFetchError] = useState(false);
    const [aptInfoFetchError, setAptInfoFetchError] = useState(false);
    const [aptA1B1InfoFetchError, setAptA1B1InfoFetchError] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      switch (name) {
        case 'aptNumber1':
          setFormData(prev => ({ ...prev, aptNumber1: value}));
          break;
        case 'aptNumber2':
          setFormData(prev => ({ ...prev, aptNumber2: value}));
          break;
        case 'aptNumber3':
          setFormData(prev => ({ ...prev, aptNumber3: value}));
          break;
        default:
          break;
      }
    };
  
    const handleInputChangeForAptNo = (e) => {
      const value = e.target.value;
      setAptName(value);
      fetchSuggestions(value);
    }

    const fetchSuggestions = (query) => {
      if (query.length === 0) {
        setSuggestions([]);
        return;
      }
      const apiUrl = `https://apartment.codok.org/autocomplete?q=${encodeURIComponent(query)}`;
      // const apiUrl = `http://127.0.0.1:5001/autocomplete?q=${encodeURIComponent(query)}`;
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const parser = new DOMParser();
          const textOnlyData = data.map(item => parser.parseFromString(item, 'text/html').body.textContent);
          setSuggestions(textOnlyData);
        })
        .catch(error => console.error('Error:', error));
    };
  
    const getAptListA1B1Info = (apt_no, pyeongNo, isRent) => {
      setIsFetchingAptA1B1Info(true)
      const apiUrl = 'https://apartment.codok.org/apt_sale_info'; // 실제 API 엔드포인트를 넣어주세요 
      // const apiUrl = 'http://127.0.0.1:5001/apt_sale_info';
      // POST 요청 보내기
      fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ apt_no, pyeongNo, isRent }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((aptA1B1Info) => {
          setIsFetchingAptA1B1Info(false)
          setAptA1B1ListInfo(aptA1B1Info)
          setAptA1B1InfoFetchError(false)
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
          setAptA1B1InfoFetchError(true)
          setAptA1B1ListInfo(null)
        })
    }
    const getAptInfo = (apt_no) => {
        setIsFetchingAptInfo(true)
        const apiUrl = 'https://apartment.codok.org/apt'; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = 'http://127.0.0.1:5001/apt';
        // POST 요청 보내기
  
        const bodyData = apt_no ? {"aptNumber1": apt_no, "aptNumber2":"", "aptNumber3":""} : formData
        fetch(apiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: bodyData }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((aptInfo) => {
            setIsFetchingAptInfo(false)
            setAptInfo(aptInfo)
            setAptInfoFetchError(false)
            setSuggestions([]);
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setAptInfoFetchError(true)
            setAptInfo(null)
          })
    }
    const searchAptNo = (name) => {
        setIsFetchingAptNoInfo(true)
        const apiUrl = 'https://apartment.codok.org/search_apt'; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = 'http://127.0.0.1:5001/search_apt';
        // POST 요청 보내기
        fetch(apiUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ searchAptName: name }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((aptNoInfoDict) => {
            setIsFetchingAptNoInfo(false)
            setAptNoInfo(aptNoInfoDict)
            setAptNoInfoFetchError(false)
          })
          .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
            setAptNoInfoFetchError(true)
            setAptNoInfo(null)
          }
        )
    }
  
    const handleSearch = (e) => {
      e.preventDefault(); // 기본 이벤트를 먼저 방지
      searchAptNo(aptName)
      setAptName(""); // 입력 필드 초기화
      setAptNoInfo(null)
      setSuggestions([]);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault(); // 기본 이벤트를 먼저 방지
  
      // 첫 번째 필드가 숫자가 아닐 경우
      if (!/^\d+$/.test(formData.aptNumber1)) {
        alert('아파트 번호로만 작동합니다. 사용설명서 확인 부탁드려요~');
        setFormData({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""}); // 입력 필드 초기화
        return; // 함수를 여기서 종료
      }
    
      // 두 번째 필드 검증 (첫 번째 필드에 값이 있고, 두 번째 필드가 숫자가 아닐 경우)
      if (formData.aptNumber1 && formData.aptNumber2 && !/^\d+$/.test(formData.aptNumber2)) {
        alert('아파트 번호로만 작동합니다. 사용설명서 확인 부탁드려요~');
        setFormData({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""}); // 입력 필드 초기화
        return; // 함수를 여기서 종료
      }
    
      // 세 번째 필드 검증 (첫 번째와 두 번째 필드에 값이 있고, 세 번째 필드가 숫자가 아닐 경우)
      if (formData.aptNumber1 && formData.aptNumber2 && formData.aptNumber3 && !/^\d+$/.test(formData.aptNumber3)) {
        alert('아파트 번호로만 작동합니다. 사용설명서 확인 부탁드려요~');
        setFormData({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""}); // 입력 필드 초기화
        return; // 함수를 여기서 종료
      }
  
      getAptInfo()
      setFormData({"aptNumber1":"", "aptNumber2":"", "aptNumber3":""}); // 입력 필드 초기화
      setAptInfo(null)
      setAptA1B1ListInfo(null)
    };
  
    const extractApartmentName = (fullName) => {
      const result = fullName.replace(/^[^동]*동\s*/, '');
      return result;
    };
  
    const selectSuggestion = (suggestion) => {
      setAptName(extractApartmentName(suggestion));
      setSuggestions([]);
    };
  
    return (
      <section className='box-border flex flex-col'>
        <SearchByRegion setAptA1B1ListInfo={setAptA1B1ListInfo} setAptInfo={setAptInfo} />
        <hr />
        <SearchAptNoForm handleSearch={handleSearch} aptName={aptName} handleInputChange={handleInputChangeForAptNo} suggestions={suggestions} selectSuggestion={selectSuggestion} />
        <hr />
        {
          isFetchingAptNoInfo && !aptNoInfoFetchError ? 
          <div className='m-2 p-2'>
            <button disabled type="button" className="text-white bg-blue-500 font-semibold text-xl rounded-lg px-5 py-2.5 text-center me-2 inline-flex items-center">
              <Spin />
              아파트_no 찾는중...
            </button>
          </div> : 
          <TableComponent columnOrder={columnOrderForAptNoInfo} data={aptNoInfo}  />
        }
        { aptNoInfoFetchError ? 
          <div className='m-2 p-2'>
            <p className='text-2xl'>아파트를 찾을 수 없습니다. 지역과 함께 아파트명 입력해보세요👍 {`(ex:죽전 동성아파트)`}</p>
            <hr />
          </div> : null
        }
        {
          <SearchForm handleSubmit={handleSubmit} formData={formData} handleInputChange={handleInputChange} />
        }
        
        {
            <ResultOfApartmentContainer 
              isFetchingAptInfo={isFetchingAptInfo} 
              aptInfoFetchError={aptInfoFetchError}
              aptInfo={aptInfo}
              columnOrderForAptInfo={columnOrderForAptInfo}
              getAptListA1B1Info={getAptListA1B1Info}
              isFetchingAptA1B1Info={isFetchingAptA1B1Info}
              aptA1B1InfoFetchError={aptA1B1InfoFetchError}
              aptA1B1ListInfo={aptA1B1ListInfo}
              columnOrderForAptA1B1Info={columnOrderForAptA1B1Info}
            />          
        }
      </section>
    );
}

