// components/TableComponent.jsx
import React from 'react';
import { downloadCSV } from '../util/helper';
import { useNavigate } from 'react-router-dom'; // 추가된 부분

function TableComponent({ data, columnOrder, getAptListA1B1Info }) {
  const navigate = useNavigate(); // 추가된 부분
  if (!data) return null;

  const filteredColumnOrder = columnOrder.filter(column => 
    !['complexName', 'complexNo', '총세대수', '아프트_총_동수', '입주시점', '용적률', '건폐율', '주소'].includes(column)
  );


  const handleButtonClick = (complexNo, pyeongNo, isRent) => {
    // URL 변경
    navigate(`/apartment/${complexNo}/${pyeongNo}?rent=${isRent}`);
    // 데이터 가져오기
    getAptListA1B1Info(complexNo, pyeongNo, isRent);
  };

  return (
    <div className='m-2 p-2 overflow-x-auto w-full'>
      <div className='flex items-center'>
      <button className="mb-3 p-2 bg-blue-400 text-white rounded" onClick={() => downloadCSV(data, filteredColumnOrder)}>
        Download CSV
      </button>
      <di className="ml-3 mb-3 p-2 lg:hidden">➡️ 왼쪽으로 스크롤하세요</di>
      </div>
      <table className='border border-collapse border-black table-auto w-full'>
        <thead>
          <tr>
            {filteredColumnOrder.map((column, index) => (
              <th key={index} className='border border-collapse border-black text-left bg-blue-100 p-1'>
                <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">{column}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[filteredColumnOrder[0]]?.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {filteredColumnOrder.map((column, colIndex) => {
                if (column === "매물_리스트_확인") {
                  return (
                    <td key={colIndex} className='border border-collapse border-black text-left p-1'>
                      <div className="flex space-x-1">
                        <a href={`/apartment/${data["complexNo"][rowIndex]}/${data["평_no"][rowIndex]}?rent=${false}`} className="p-1 bg-blue-400 text-white rounded-md flex-1 text-sm whitespace-nowrap" >매매</a>
                        <a href={`/apartment/${data["complexNo"][rowIndex]}/${data["평_no"][rowIndex]}?rent=${true}`} className="p-1 bg-orange-300 text-black rounded-md flex-1 text-sm whitespace-nowrap" >전세</a>
                      </div>
                    </td>
                  );
                } else {
                  return <td key={colIndex} className='border border-collapse border-black text-left p-1 text-sm'>{data[column][rowIndex]}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;