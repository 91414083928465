import React from 'react';
import { Link } from 'react-router-dom';

const SearchByRegion = ({ setAptInfo, setAptA1B1ListInfo }) => {
    const handleClick = () => {
        setAptInfo(null)
        setAptA1B1ListInfo(null)
    }

    return (
        <div className='m-2 p-2 lg:w-1/2'>
            <p className="text-lg md:text-xl font-semibold pb-2 px-2">
                지역별로 아파트 찾으실 수 있습니다.
            </p>
                <Link to="/area">
                    <button
                        onClick={handleClick}
                        className='px-5 py-2 bg-blue-400 text-white rounded-md w-full md:w-1/3 lg:w-2/6'
                    >
                        아파트 찾기
                    </button>
                </Link>
        </div>
    );
};

export default SearchByRegion;