//components/SearchAptNoForm.jsx

import React from 'react';

export default function SearchAptNoForm({ handleSearch, aptName, handleInputChange, suggestions, selectSuggestion }) {
    const isFieldsEmpty = !aptName;
    const baseInputClass = 'outline-none border border-gray-300 p-2 rounded-md w-full md:w-2/3 lg:w-1/2 mb-2 md:mb-0 md:mr-2';

    return (
        <div className='m-2 p-2 lg:w-1/2'>
            <p className="text-lg md:text-xl font-semibold pb-2 px-2">아파트 이름 입력 ➡️ 아파트 No 확인</p>
            <form onSubmit={handleSearch} className="flex flex-col md:flex-row">
                <input
                    type="text"
                    placeholder="아파트 이름 입력"
                    value={aptName}
                    onChange={handleInputChange}
                    name="search_apt_name"
                    className={baseInputClass}
                />
                <button 
                    type="submit"
                    disabled={isFieldsEmpty}
                    className='px-5 py-2 bg-blue-400 text-white rounded-md w-full md:w-1/3 lg:w-2/6'
                >
                    아파트 No 찾기
                </button>
            </form>
            {suggestions.length > 0 && (
                <div id="suggestions" className="border border-gray-300 mt-1 rounded-md w-full md:w-2/3 lg:w-1/2">
                    {suggestions.map((item, index) => (
                        <div
                            key={index}
                            className="suggestion-item p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => selectSuggestion(item)}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}